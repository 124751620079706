import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from "@reduxjs/toolkit"
import { createInjectorsEnhancer } from "redux-injectors"
import { createLogger } from "redux-logger"
import createSagaMiddleware from "redux-saga"
import { createReducer } from "./reducers"

export function configureAppStore() {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]

  // Redux logger
  if (process.env.NODE_ENV !== "production") {
    const logger = createLogger({
      collapsed: () => true,
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middlewares.push(logger)
  }

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[]

  return configureStore({
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== "production",
    enhancers,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middlewares,
    ],
    reducer: createReducer(),
  })
}
